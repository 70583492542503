/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import get from 'lodash.get';
import { 
    API_IN_PROGRESS, 
    GET_QUESTION_FAILURE, 
    SET_ACTION_FAILURE_MESSAGE,
} from '../types';
import { axiosInstance, axiosgetInstance } from '../../util/axios';
import LocalStorageServices from '../../util/localStorageServices';
import { getCookie } from '../../utils';
import { getNextQuestionSet } from './questions';
import config from '../../config';

const OIDC_AUTH_API = get(config, 'features.login.OIDCAuthURL');
const isPlanBasedRoute = process.env.REACT_APP_IS_PLAN_BASED_ROUTE === 'true';
const lifePlan = process.env.REACT_APP_LIFE_PLAN;

/**
 * @description Fetches Auth Tokens and stores them to LocalStorage.
 */
export const getJWTAuthToken = (
	url, 
	reqParams,
	params = {
		redirectTo: isPlanBasedRoute ? `/${lifePlan}` : '/',
	},
) => {
	return async (dispatch, getState) => {
    const state = getState();
		const hasLoginEnabled = get(state, 'auth.login.enable', false);
		dispatch({
			type: API_IN_PROGRESS,
		});
	await	axiosInstance
			.post(url, reqParams)
			.then((response) => {
				const accessToken = get(
					response,
					'data.access_token',
					null,
				);
				const expiresOn = get(
					response,
					'data.expires_on',
				);
				const refreshToken = get(
					response,
					'data.refresh_token',
					null,
				)
				const item = {
					value: accessToken,
					expiry: expiresOn,
					refresh: refreshToken,
				}
				if (accessToken) {
					LocalStorageServices.setToken(JSON.stringify(item))
          if(hasLoginEnabled) {
            window.location.replace(params.redirectTo); 	
          }	
				}
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: GET_QUESTION_FAILURE,
				});
				if (error.response) {
					const errorMessage = get(error.response, 'data.errors.message', 
						'Some Error Occured!');
					dispatch({
						type: SET_ACTION_FAILURE_MESSAGE,
						payload: {
							actionMessage: {
								type: 'error',
								message: errorMessage,
							},
						},
					});
				}
			});
    return !!LocalStorageServices.getAccessToken();
	}
}


/**
 * @description Fetches OIDC third party url for user.
 */
export const OIDCAuthGetApi = (url) => {
  return (dispatch) => {
    dispatch({
      type: API_IN_PROGRESS,
    });
    axiosgetInstance
      .get(url)
      .then((response) => {
        if (response) {
          const srvType = get(response, 'data.data.srv_type', '');
          if (srvType && (srvType === 'OIDC' || srvType === 'SAML2')) {
            const redirectUrl = get(response, 'data.data.redirect.url', '');
            window.location.replace(redirectUrl);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: GET_QUESTION_FAILURE,
        });
        if (error.response) {
          const errorMessage = get(
            error.response,
            'data.errors.message',
            'Some Error Occured!',
          );
          dispatch({
            type: SET_ACTION_FAILURE_MESSAGE,
            payload: {
              actionMessage: {
                type: 'error',
                message: errorMessage,
              },
            },
          });
        }
      });
  };
};

/**
 * @description Fetches Auth Tokens and stores them to LocalStorage after OIDC third party verification.
 */
export const OIDCAuthPostApi = (
  url,
  reqParams,
  params = {
    redirectTo: isPlanBasedRoute ? `/${lifePlan}` : '/',
  }
) => {
  return (dispatch) => {
    dispatch({
      type: API_IN_PROGRESS,
    });
    axiosInstance
      .post(url, reqParams)
      .then((response) => {
        const accessToken = get(response, 'data.data.response.access_token', null);
        const expiresOn = get(response, 'data.data.response.expires_at');
        const refreshToken = get(response, 'data.data.response.refresh_token', null);
        const item = {
          value: accessToken,
          expiry: expiresOn,
          refresh: refreshToken,
        };
        if (accessToken) {
          LocalStorageServices.setToken(JSON.stringify(item));
          const navigationUrlObj = JSON.parse(window.sessionStorage.getItem('navigationUrlObj')); 
          if(navigationUrlObj && navigationUrlObj.url){
            // navigationUrlObj.skipRoleCheck = true;
            // window.sessionStorage.setItem('navigationUrlObj', JSON.stringify(navigationUrlObj));
            window.location.replace(navigationUrlObj.url);
          }else
          window.location.replace(params.redirectTo);
        }
      })
      .catch((error) => {
		  console.error(error);
		  dispatch({
			  type: GET_QUESTION_FAILURE,
		  });
		  if (error.response) {
			  dispatch(getNextQuestionSet('/questions', { oidc_auth_fail: 1 }));
		  }
      });
  };
};

/**
 * @description Performs /auth API call and URL redirection.
 */
export const triggerAuthAction = () => {
    return async(dispatch, getState) => {
        const state = getState();
        const loginURL = get(state, 'auth.login.loginURL', '/auth');
        const reqParams = {
            carrier: process.env.REACT_APP_CARRIER_ID,
        }
        const hasAuthToken = await dispatch(getJWTAuthToken(loginURL, reqParams));
        return hasAuthToken;
    }
}

/**
 * @description Performs OIDC third party get auth/login API call and URL redirection.
 */
export const triggerOIDCgetApiAction = () => {
    return (dispatch, getState) => {
        const state = getState();
        const OIDCAuthURL = get(state, 'auth.login.OIDCAuthURL', OIDC_AUTH_API);
        dispatch(OIDCAuthGetApi(OIDCAuthURL));
    }
}

/**
 * @description Performs OIDC third party post auth/login API call and URL redirection.
 */
export const triggerOIDCpostApiAction = (code) => {
    return (dispatch, getState) => {
        const state = getState();
        const OIDCAuthURL = get(state, 'auth.login.OIDCAuthURL', OIDC_AUTH_API);
		    const reqParams = {
            ...code,
            amendment_acceptance: 0,
            browser: '',
            device_id: '',
  			device_model: '',
  			device_token: '',
  			endorsement_delivery: 0,
  			os: '',
  			timezone_name: '',
  			unique_id: '',
  			user_timezone: '',
        }
        dispatch(OIDCAuthPostApi(OIDCAuthURL, reqParams, { redirectTo: isPlanBasedRoute ? `/${lifePlan}` : '/' }));
    }
}

// Auth api call to get the refresh token incase of no refresh token in local storage and cookies 
const getRefreshTokenFromAuth = () => {
	return async (dispatch, getState) => {
      const state = getState();
      const loginURL = get(state, 'auth.login.loginURL', '/auth');
        const reqParams = {
            carrier: process.env.REACT_APP_CARRIER_ID,
        }
        	dispatch({
			type: API_IN_PROGRESS,
		});
			await axiosInstance
			.post(loginURL, reqParams)
			.then(response => {
					const accessToken = get(
					response,
					'data.access_token',
					null,
				);
				const expiresOn = get(
					response,
					'data.expires_on',
				);
				const refreshToken = get(
					response,
					'data.refresh_token',
					null,
				)
				const item = {
					value: accessToken,
					expiry: expiresOn,
					refresh: refreshToken,
				}
				if (accessToken) {
					LocalStorageServices.setToken(JSON.stringify(item));	
				}
        })
        .catch((error) => {
        console.error(error);
				dispatch({
					type: GET_QUESTION_FAILURE,
				});
				if (error.response) {
					const errorMessage = get(error.response, 'data.errors.message', 
						'Some Error Occured!');
					dispatch({
						type: SET_ACTION_FAILURE_MESSAGE,
						payload: {
							actionMessage: {
								type: 'error',
								message: errorMessage,
							},
						},
					});
				}
     })
        
    }
}

/**
 * @description Performs refresh_token API call, fetches new tokens and stores them in LocalStorage.
 */
export const getRefreshToken = () => {
	return async (dispatch, getState) => {
        const state = getState();
        const refreshURL = get(state, 'auth.login.refreshURL', '/auth/refresh_token');
		let refreshToken = LocalStorageServices.getRefreshToken();
    if(!refreshToken){
       refreshToken = getCookie('refresh_token');
    }
		if (refreshToken) {
      const reqParams = {
			   refresh_token: refreshToken,
		  }
			await axiosInstance
			.post(refreshURL, reqParams)
			.then(res => {
				const _accessToken = get(
				res,
				'data.access_token',
				null,
				);
				const _expiresOn = get(
				res,
				'data.expires_on',
				);
				const _refreshToken = get(
				res,
				'data.refresh_token',
				null,
				)
				const item = {
				value: _accessToken,
				expiry: _expiresOn,
				refresh: _refreshToken,
				}
				if (_accessToken) {
					LocalStorageServices.clearToken();
					LocalStorageServices.setToken(JSON.stringify(item));
                }
            })
            .catch(async() => {
                const isLoginPageEnabled = get(state, 'auth.login.enable', false);
                if (!isLoginPageEnabled) {
                   await dispatch(triggerAuthAction());
                } else {
                    window.location.reload();
                }
            })
        }else{
                const isLoginPageEnabled = get(state, 'auth.login.enable', false);
                if (!isLoginPageEnabled) {
                    LocalStorageServices.clearToken();
                   await dispatch(getRefreshTokenFromAuth());
                }
        }
        return LocalStorageServices.getAccessToken();
    }
}


/**
 * @description Perform logout API call and removes Auth tokens from LocalStorage.
 * Also take care of retry logics.
 */
const removeJWTAuthToken = (
	url,
	reqParams,
	params = {
		redirectTo: isPlanBasedRoute ? `/${lifePlan}` : '/',
	},
) => {
	return (dispatch, getState) => {
		dispatch({
			type: API_IN_PROGRESS,
		});
		axiosInstance
			.post(url, reqParams)
			.then((response) => {
				if (response) {
					LocalStorageServices.clearToken();
					window.location.replace(params.redirectTo);
				}
			})
			.catch(async(error) => {
                console.error(error);
				const refreshRetry = get(error, 'response.config._retry', false);
				if (refreshRetry) {
                    const state = getState();
                    const logoutURL = get(state, 'auth.login.logoutURL', '/auth/logout');
                    const originalRequest = error.config;
                    const accessToken = await dispatch(getRefreshToken());
                    if (accessToken) {
                        // Replace with new access_token value present in the case of logout.
                        if (originalRequest.data && originalRequest.url.includes(logoutURL)) {
                            let requestData = originalRequest.data;
                            requestData = JSON.parse(requestData);
                            requestData.access_token = accessToken;
                            originalRequest.data = requestData;
                        }
                        axiosInstance
                        .post(originalRequest.url, originalRequest.data)
                        .then((response) => {
                            if (response) {
                                LocalStorageServices.clearToken();
                                window.location.replace(params.redirectTo);
                            }
                        })
                    }
                } else {
					dispatch({
						type: GET_QUESTION_FAILURE,
					});
					dispatch({
						type: SET_ACTION_FAILURE_MESSAGE,
					});
				}
			});
	}
}

/**
 * @description Removes Auth Token from local storage and redirects user to homepage (/ by default)
 */
export const removeAuthHeaders = () => {
	return (dispatch, getState) => {
        const state = getState();
        const logoutURL = get(state, 'auth.login.logoutURL', '/auth/logout');
        const accessToken = LocalStorageServices.getAccessToken();
		if (accessToken) {
			const reqParams = {
				access_token: accessToken,
			}
			dispatch(
				removeJWTAuthToken(logoutURL, reqParams, {
					redirectTo: isPlanBasedRoute ? `/${lifePlan}` : '/',
				}),
			)
		} else {
			window.location.replace('/');
		}
	}
}