/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import get from 'lodash.get';
import intersection from 'lodash.intersection';
import omit from 'lodash.omit';
import isEmpty from 'lodash.isempty';
import { isObject , getUTCTimeStamp } from '../../utils';
import { axiosInstance } from '../../util/axios';
import { 
	  UPDATE_QUESTION_ANSWER, 
	  UPDATE_DATA_SELECTOR_OPTIONS,
      GET_NEW_QUESTION_START, 
      SET_CURRENT_BREADCRUMB, 
	  GET_QUESTION_FAILURE,
	  SET_ACTION_SUCCESS_MESSAGE,
	  SET_ACTION_FAILURE_MESSAGE,
	  TOGGLE_ESIGN_BUTTON,
	  UPDATE_LAST_FOCUSED_ELEMENT_ID,
	  SET_POPUP_DETAILS,
	  UPDATE_QUESTION_ANSWER_TIME_STAMP,
} from '../types';
import { setUid } from './configActions';
import router from '../../util/router';
import { getRefreshToken, getJWTAuthToken } from './authHandler';
import prepareQuestionsPayload from '../../util/questionFormatter';
import HELPER from '../reducers/helper';
import { checkTokenExpiry, getTabId } from '../../util';

const isPlanBasedRoute = process.env.REACT_APP_IS_PLAN_BASED_ROUTE === 'true';
const lifePlan = process.env.REACT_APP_LIFE_PLAN;

export const setPopupDetails = (payload) => ({
	type: SET_POPUP_DETAILS,
	payload,
});

const makeGetNextQuestionSetCall = ({ url, updatedFormData, params }) => {
	return (dispatch) => {
		console.log('Begin axios instance questions call', new Date().getTime());
		axiosInstance
		.post(url, updatedFormData)
		.then((response) => {
			console.log('End axios instance questions call', new Date().getTime());
			console.log('Begin axios instance response processing', new Date().getTime());
			// identity check, if response is valid, show the requested page,
			// else /inactivesession response is handled gtom server side.
			const identity = get(response, 'config._identity', 'invalid');
			if (identity === 'valid') {
				const uid = get(
					response,
					'data.response.other_params.uid',
					'',
				);
				const currentStatus = get(
					response,
					'data.response.other_params.current_status',
					'',
				).toUpperCase();
				router(dispatch, uid, params.isReflexive, response);
				dispatch(setUid(uid));
				dispatch({
					type: `GET_${currentStatus}_SUCCESS`,
					payload: get(response, 'data.response', null),
					params,
				});
				if (!isEmpty(params.actionProps)) {
					if (get(params, 'actionProps.success.message')) {
						dispatch({
							type: SET_ACTION_SUCCESS_MESSAGE,
							payload: {
								actionMessage: {
									type: 'success',
									message: get(params, 'actionProps.success.message', null),
								},
							},
						})
					}
				}
			}
			console.log('End axios instance response processing', new Date().getTime());
		})
		.catch(async(error) => {
			console.error(error);
			axiosInstance.post('/logs', [{ uid: updatedFormData.uid }, error]);

			let errorMessage = get(error, 'response.data.errors');
			// Fetch userMessage message from errors object if errors is not of type string.
			if (typeof errorMessage !== 'string') {
				errorMessage = get(error, 'response.data.errors.userMessage', 'Some Error Occured!');
			}
			dispatch({
				type: GET_QUESTION_FAILURE,
			});
			dispatch({
				type: SET_ACTION_FAILURE_MESSAGE,
				payload: {
					actionMessage: {
						type: 'error',
						message: errorMessage,
					},
				},
			});
		})
	}
}

export const getNextQuestionSet = (
	url,
	formData,
	params = {
		isReflexive: false,
		sectionIndex: 0,
		validate: false,
		isListAddAction: false,
		reload: false,
		actionProps: {},
		breadcrumbNavigation: false,
		actionMessage: { type: '', message: '' },
		maskedQArray: [],
		listActionId: undefined,
	},
) => {
	return async (dispatch, getState) => {
		const state = getState();
		const { role, sections, activeSubsectionId, sectionIndex } = state.questions;
		const queryUid = get(state, 'router.location.query.uid', '');
		const roleParams = {};
		if(role){
		  roleParams.role = role;	
		}
		const updatedFormData = queryUid
		  ? { ...formData, uid: queryUid, ...roleParams }
		  : { ...formData, ...roleParams };

		  if (activeSubsectionId && (sectionIndex || sectionIndex === 0)) {
			const currentSection = sections[activeSubsectionId][sectionIndex];
			const signType = get(currentSection, 'otherParams.sign_type');

			if (signType) updatedFormData.sign_type = signType;
		}

		// remove preservedAnswers from session only if preserved question ids included on formData questionsIds
		if ((updatedFormData.next_page_flag === 1 || updatedFormData.reflexive_question_flag === 1) && updatedFormData.questions) {
			const preservedAnswers = JSON.parse(window.sessionStorage.getItem('preservedAnswers')) || [];
			const preservedQIds = preservedAnswers.map(q => q.question_id);
			const reqQIDs = updatedFormData.questions.map(q => q.question_id)
			if (preservedQIds.every(pQId => reqQIDs.includes(pQId))) {
				window.sessionStorage.removeItem('preservedAnswers');
			}
		}

		if (window.location.pathname === '/inactivesession') {
			return;
		}
		updatedFormData.tabId = getTabId();

		dispatch({
			type: GET_NEW_QUESTION_START,
			updatedFormData,
			params,
		});

		if (!params.isReflexive || params.listActionId) {
			dispatch({
				type: UPDATE_LAST_FOCUSED_ELEMENT_ID,
				payload: {
					id: params.listActionId || 'btn-continue',
				},
			});
		}

		// check for token expiry
		let isTokenExpired = false;
		if (process.env.REACT_APP_ENABLE_AUTH_API === 'TRUE') {
			isTokenExpired = checkTokenExpiry();
		}
		console.log('Is auth token expired: ', isTokenExpired);
		if (isTokenExpired) {
			const accessToken = await dispatch(getRefreshToken());
			if (accessToken) {
				dispatch(makeGetNextQuestionSetCall({
					url,
					updatedFormData,
					params,
				}))
			}
		} else {
			dispatch(makeGetNextQuestionSetCall({
				url,
				updatedFormData,
				params,
			}))
		}
	};
}

export const getAuthQuestionSet = (response) => {
	return (dispatch) => {
		const currentStatus = get(
			response,
			'data.response.other_params.current_status',
			'',
		).toUpperCase();
		if(currentStatus && currentStatus !== 'UNAUTHORIZE'){
          router(dispatch, null, false, response);
		}
		dispatch({
			type: `GET_${currentStatus}_SUCCESS`,
			payload: get(response, 'data.response', null),
			params: {},
		});
	}
}

export const getSectionAnswers = (currentBreadcrumb)=>{
	const answersArray = []
	currentBreadcrumb.forEach(section => section.answersArray.forEach(
		answer => (answer.answer || answer.answer === 0) && answersArray.push(answer)))
	let questionList = currentBreadcrumb.reduce((acc, section)=> {
		return [...acc, ...section.questionList]
	}, [])
	questionList = questionList.filter(question => question.presentation_type !== 'review')
	return {
	  answersArray,
	  questionList,
	}
}

export const getAnsweredListQuestion = (currentBreadcrumb) => {
	const answeredListQuestion = currentBreadcrumb.reduce((acc, section)=> {
		return [...acc, ...section.answeredListQuestion]
	}, [])
	return answeredListQuestion;
}

const formatAuthAnswers = (answersArray) => {
	const reducer = (accumulator, currentValue) => {
		accumulator[currentValue.question_id] = currentValue.answer;
		return accumulator;
	  }
	return answersArray.reduce(reducer, {});
}

export const submitAuthQuestion = () => {
	return (dispatch, getState) => {
		const state = getState();
		const { questions } = state;
		const { activeSubsectionId } = questions;
		const currentSection = questions.sections[activeSubsectionId];
		const  { answersArray } = getSectionAnswers(currentSection);
		
		const defaultDestinationURL = isPlanBasedRoute ? `/${lifePlan}` : '/';
		const destinationURL = get(state, 'auth.destinationURL', defaultDestinationURL);
		const loginURL = get(state, 'auth.login.loginURL', '/auth');
		let reqParams;
		if (!isEmpty(answersArray)) {
			reqParams = formatAuthAnswers(answersArray);
		}
		dispatch(
			getJWTAuthToken(loginURL, reqParams, {
				redirectTo: destinationURL,
			}),
		);
	}
}

const insertMissingAnswers = (
	answersArray,
	questionList,
	isReflexive,
) => {
	return questionList.reduce((acc, question) => {
		const alreadyAnsweredQuestionObject = answersArray.filter(
			(item) => (item.question_id === question.question_id) && !question.is_hidden,
		);
		if ((question.display_type === 'breadcrumb' || (question.display_type === 'list' && question.submission_type === 'all')) && !question.is_hidden) {
			return acc;
		}
		if (alreadyAnsweredQuestionObject.length) {
			acc.push(...alreadyAnsweredQuestionObject);
			return acc;
		}
		if (question.question_id && question.display_type && !question.is_hidden) {
			if (!isReflexive)
				acc.push({
					question_id: question.question_id,
					answer: '',
				});
		}
		return acc;
	}, []);
};

const appendListCompletionFlag = (questions, answeredListQuestion) => {
	answeredListQuestion.map((qId) => {
		if(!questions.find(question => question.question_id=== qId)) {
			questions.push({
				question_id: qId,
				answer:[],
			})
		}
		return null;
	})
	const appended = questions.map(question=> {
		if(answeredListQuestion.indexOf(question.question_id)>-1){
			return {
				...question,
				answer:[],
				extra_params: {
          			list_completed_flag: true,
        		},
			}
		}
		return question;
	})
	return appended;
}

const getNListAnswers = (currentSubsection) => {
	const { answersArray } = currentSubsection;
	return answersArray.filter(a => a.question_id.match(/_\$ureify_/));
}

const formatListQuestions = (questions) => {
	const listQs = questions.filter(q => q.listParentId);
	const otherQs = questions.filter(q => !q.listParentId);
	const extraParams = {
		list_completed_flag: false,
	};
	if (listQs && listQs.length) {
		const { listParentId, listIndex } = listQs[0];
		const answer = [];
		if (Number.isInteger(listIndex) && listIndex >= 0) {
			extraParams.current_list_answer_index = listIndex;
		}
		listQs.forEach(q => {
			const temp = { question_id: q.question_id, answer: q.answer };
			answer.push(temp);
		})
		return [
			{
				question_id: listParentId,
				answer,
				extra_params: extraParams,
			},
			...otherQs,
		]
	}
	return questions;
}

export const submitSectionQuestion = (
	sectionIndex,
	otherParams = {
		isReflexive: false,
		sectionIndex: 0,
		validate: false,
		actionProps: {},
		removeIndex: {},
		calculateClick: false,
		requiredQuestions: [],
		csrConfig: null,
		finalQuoteCsrConfig: null,
		onClick: '',
		attempt: 0,
		hasModal: false,
		isBackLink: false,
	},
	action= '',
	url = '/questions',
) => {
  return (dispatch, getState) => {
    const state = getState();
		const { questions, uid } = state;
		const { activeSubsectionId } = questions;
		const pageSeqNumber = get(
			questions,
			'currentPageSeqNumber',
			1,
		);
		const currentSection =
			questions.sections[activeSubsectionId];
		const currentSubsection = currentSection[sectionIndex];
		const { completed, questionList: currentQuestionList } = currentSubsection;
		let allQuestions = [];

		if (otherParams.onClick) {
			dispatch({
				type: UPDATE_LAST_FOCUSED_ELEMENT_ID,
				payload: {
					id: otherParams.onClick,
				},
			});
		}

		const { answersArray, questionList } = getSectionAnswers(currentSection);

		if (otherParams.isReflexive || otherParams.validate) {
			allQuestions =  answersArray;
		} else {
			allQuestions = [
				...insertMissingAnswers(answersArray, questionList),
				...getNListAnswers(currentSubsection),
			]
		}
		let requiredHiddenQuestions = questionList.filter(q => q.is_hidden && q.properties && q.properties.add_to_payload);
		requiredHiddenQuestions = requiredHiddenQuestions.map(q => {
			return {
				'question_id': q.question_id,
				'answer': q.response,
			}
		});
		if (requiredHiddenQuestions?.length) {
			allQuestions.push(...requiredHiddenQuestions);
		}

		// skip answers with mask validation(hasMask: true) if they are not edited (hasEdit: false)
		// allow answers with mask validation if they are edited to a new value (hasMask: true & hasEdit: true)
		const maskedQArray = HELPER.getMaskedResponses(questionList, answersArray)
		if (maskedQArray.length) {
			const maskedQIds = [];
			maskedQArray.forEach(arr => {
				const answeredObject = answersArray.filter(
					(item) => item.question_id === arr.question_id && !item.hasEdit,
				);
				if (answeredObject.length) maskedQIds.push(arr.question_id);
			})
			allQuestions = allQuestions.filter(q => !maskedQIds.includes(q.question_id));
		}

		allQuestions = allQuestions.map(q => omit(q, ['hasMask', 'hasEdit']));

		let listQId = '';
		let listIdx = null;
		const listQ = allQuestions.filter(q => q.listParentId)
		const isListAddAction = !!listQ.length;
		const isListEditAction = !!listQ.filter(
			q => Number.isInteger(q.listIndex) && q.listIndex >= 0).length;
		if (listQ.length) {
			const { listParentId, listIndex } = listQ[0];
			listQId = listParentId;
			listIdx = listIndex;
		}

		// Don't add list_completed_flag in case of list edit action
		if (!isListEditAction) {
			const answeredListQuestion = getAnsweredListQuestion(currentSection);
			allQuestions = appendListCompletionFlag(allQuestions, answeredListQuestion)
		}
		allQuestions = formatListQuestions(allQuestions);

		// omit questions from csr section whose button is enabled
		// const csrSections = Object.keys(csrConfig);
		// const activeCsrSection = csrSections.filter(s => csrConfig[s].enable)[0];
		// if (role === 'csr' && activeCsrSection) {
		// 	const { questionList: allSectionsQuestions } = getCompiledQuestionsAnswersErrors(state);
		// 	const omitCsrQuestions = allSectionsQuestions.filter(q => activeCsrSection.includes(get(q, 'properties.section.value'))).map(q => q.question_id);
		// 	allQuestions = allQuestions.filter(a => !omitCsrQuestions.includes(a.question_id));
		// }

		let payload = prepareQuestionsPayload(
			otherParams.requiredQuestions && otherParams.requiredQuestions.length ? otherParams.requiredQuestions : allQuestions,
			state.meta.listRelation[activeSubsectionId],
			otherParams.removeIndex,
		);

		// Checking if there is any html string and removing its object in payload to fix cloudfare issue
	    /* eslint-disable no-param-reassign */

		payload = payload.filter((quesn) => 
			/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(quesn.answer) === false,
		);
		
		let reqParams = {
			uid,
			page_sequence_number: pageSeqNumber,
			questions: payload,
			...otherParams.onClick && { onClick: otherParams.onClick },
			...otherParams.attempt && { attempt: otherParams.attempt },
		};

        if (completed && !otherParams.hasModal  && !otherParams.onClick) {
			reqParams = {
				...reqParams,
				index_modify_flag: 1,
			};
		}
		// condition if backLink is called
		else if(otherParams.isBackLink) {
			reqParams = {
				...reqParams,
				prev_page_flag: true,
			  }
		} else {
			reqParams = {
				...reqParams,
				next_page_flag: 1,
			};
		}

		if (otherParams.isReflexive) {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			reqParams = {
				...reqParams,
				reflexive_question_flag: 1,
				...otherParams.calculateClick && { calculateClick: otherParams.calculateClick },
				...otherParams.csrConfig && { csrConfig: otherParams.csrConfig },
			}
			if(listQId) {
				reqParams = {
					...reqParams,
					list_question_id: listQId,
				}
			}
		}

		if (otherParams.validate) {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			reqParams = {
				...reqParams,
				dob_validation_flag: 1,
			}
		}

		if (action) {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			if (action === 'CANCEL_UPDATE')
				reqParams = omit(reqParams, 'questions');
			reqParams = {
				...reqParams,
				action,
			}
		}

		if (activeSubsectionId === 'quotereview') {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = {
				...reqParams,
				next_page_flag: 1,
			}
		}

		if (isListEditAction) {
			reqParams = {
				...reqParams,
				index_modify_flag: 1,
				list_question_id: listQId,
			}
		}

		if (!isEmpty(otherParams.actionProps)) {
			const { actionProps } = otherParams;
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			reqParams = {
				...reqParams,
				...actionProps.body,
			}
		}
		dispatch(getNextQuestionSet(url, reqParams, {
		  ...otherParams,
		  sectionIndex,
		  sectionId: get(currentQuestionList[0],'question_id',''),
		  isListAddAction,
		  ...reqParams,
		  maskedQArray,
		}));
		if (otherParams.isReflexive && isListEditAction) {
			dispatch({
				type: 'SET_REFLEXIVE_LIST_INDEX',
				index: listIdx,
				id: listQId,
			});
		}
	};
};
   
export const setCurrentBreadcrumb = (id) => ({
  type: SET_CURRENT_BREADCRUMB,
  id,
})

export const toggleESignButton = (enable) => ({
	type: TOGGLE_ESIGN_BUTTON,
	enable,
})

export const getMockQuestionSet = (response) => {
    return (dispatch) => {
        const currentStatus = get(
            response,
            'data.response.other_params.current_status',
            '',
        ).toUpperCase();
       if(currentStatus && currentStatus !== 'UNAUTHORIZE'){
          router(dispatch, null, false, response);
		}
        dispatch({
            type: `GET_${currentStatus}_SUCCESS`,
            payload: get(response, 'data.response', null),
            params: {},
        });
    }
}

const answerIsAMatch = (allAnswers, currentAnswer) => {
  if (Array.isArray(currentAnswer)) {
    return intersection(allAnswers, currentAnswer).length!==0;
  }
  let answer = currentAnswer

  if (isObject(currentAnswer)) {
    answer = currentAnswer.id;
  } else if (!Number.isNaN(Number.parseFloat(answer))) {
    answer = Number.parseFloat(currentAnswer);
  }

  answer = allAnswers.filter(a => {
    try {
      if (isObject(currentAnswer)) {
        return (a.toString() === (currentAnswer.id).toString())
      }
      return a.toString() === currentAnswer.toString()
    } catch (e) {
      console.error('Error in filter', e)
      return false
    }
  }).length
  return answer;
}

/**
 * @description function determines if reflexive question API call
 * should be done.
 * @param {Array} questionsArray existing questions and answers
 * @param {Array} childQuestionsOn options for which child questions
 * will be sent
 * @param {String} currentQuestionId
 * @param {String} currentAnswer
 * @return {Boolean}
 */
export const shouldMakeReflexiveCall = (
	questionsArray,
	childQuestionsOn,
	currentQuestionId,
	currentAnswer,
	ignoreChildQuestionsOn,
	isReflexiveQuestion,
) => {
	const hasAnswer = questionsArray.filter(
		(question) => question.question_id === currentQuestionId,
	)[0];

	const response = hasAnswer ? hasAnswer.response : undefined;

	if(response && ignoreChildQuestionsOn){
		return true;
	}

	if (childQuestionsOn && answerIsAMatch(childQuestionsOn, currentAnswer)) {
		return true;
	}
	// if there are no answer eligible for reflexive calls, return false.
	if (
		!childQuestionsOn ||
		(Array.isArray(childQuestionsOn) && childQuestionsOn.length === 0)
	) {
		return isReflexiveQuestion;
	}

	/**
	 * if there is no answer and the current answer is not part
	 * of child_questions_on array, do not make the api call.
	 * For every other condition, make the api call.
	 */
	if (!response && !answerIsAMatch(childQuestionsOn, currentAnswer)) {
		return false;
	}

	/**
	 * if we have an answer and this answer is not in the
	 * child_question_on array, AND
	 * if the new answer is also not in the child_questions_on
	 * array then do not make the api call.
	 */
	return !(response &&
		answerIsAMatch(childQuestionsOn, hasAnswer.response) &&
		answerIsAMatch(childQuestionsOn, currentAnswer));

};

// returns compiled questionns, answers & errors arrays from all the sections
export const getCompiledQuestionsAnswersErrors = (state) => {
	const sectionsKeys = Object.keys(state.questions.sections).filter(section => section !== 'testSections');
	const sectionsArr = sectionsKeys.reduce((acc, section) => {
		const sectionGroups = state.questions.sections[section].map((eachSecGroup) => {
			return {
				...eachSecGroup,
				sectionId: section,
			}
		})
		return acc.concat(sectionGroups)
	}, []);

	const totalQuestionList = sectionsArr.reduce((acc,group) => {
		const groupQList = group.questionList.map((eachQ) => {
			return {
				...eachQ,
				sectionId: group.sectionId,
			}
		})
		return acc.concat(groupQList)
	}, []);
	const totalErrorList = sectionsArr.reduce((acc,group) => acc.concat(group.errorsArray), []);

	const totalAnswerList = sectionsArr.reduce((acc,group) => acc.concat(group.answersArray), []);

	return {
		questionList: totalQuestionList,
		answerList: totalAnswerList,
		errorList: totalErrorList,
	};
}

// takes redux state, outputs last answered question
export const getLastAnsweredQuestion = (state, pageId, isAccordionExpanded) => {
	// eslint-disable-next-line prefer-const
	let { answerList = [], questionList = [] } = getCompiledQuestionsAnswersErrors(state);
	answerList = answerList.filter((question) => !!question.answer)
	if(pageId.match(/quote/i) && !isAccordionExpanded){
		answerList = answerList.filter(q => !(q?.question_id?.match(/NeedsCalc/i)))
	}

	const answerAnsweredQuestion = answerList.pop();
	const answeredQObj = questionList.filter(q => q?.question_id === answerAnsweredQuestion?.question_id)[0];

	const listQs = questionList.filter(q => q.display_type === 'list');
	const editableListQ = listQs.filter(q => q.question_id === state.questions.listEditQId)[0];
	let listQ;
	if(!editableListQ){
		if(listQs.length === 1){
			listQ = {
				...listQs[0],
				listEditMode: false,
			}
		}
	}

	return {
		answerAnsweredQuestion,
		displayType: answeredQObj?.display_type,
		listQ,
	};
};

// takes redux state, outputs first unanswered question
export const getFirstUnansweredQuestion = (state, isSkipNavigation = false) => {
	const { questionList = [], answerList = [] } = getCompiledQuestionsAnswersErrors(state);
	const requiredQs = questionList.filter(q => !q.is_hidden && (get(q, 'validations.required.value', false) || (q.display_type === 'list' && !q.child_questions_completed_flag)));
	const unansweredQs = requiredQs.filter(q => {
		const answer = get(answerList.find(ans => q.question_id === ans.question_id), 'answer');
		return !(answer || answer === 0);
	});
	const listQs = questionList.filter(q => q.display_type === 'list');

	// 1. check if any listQ is in edit mode & scroll to that
	const editableListQ = listQs.filter(q => q.question_id === state.questions.listEditQId)[0];
	if (editableListQ && !isSkipNavigation) return editableListQ;

	// 2. Check if the listQs have unanswered original_questions || question & scroll to that
	// Scenario: When editing: Policy Information > Primary Beneficiary > Type of Entity
	if (listQs.length) {
   	const listQ = listQs[0];
    const getUnansweredQListResponse = (listQuestionList, isChildQsCompleted) => {
		const unansweredQList = listQuestionList.filter((q) => (!q.response && q.display_type !== 'label'));
		const nestedUnansweredQList = listQuestionList.filter((q) => !!q.response && q.questions.length);
		// Any nested question present inside deeper level, send this to check deeper level
		if (nestedUnansweredQList.length){
			const nestedUnansweredQListRecursiveRes = getUnansweredQListResponse(nestedUnansweredQList[0].questions);
			if(nestedUnansweredQListRecursiveRes)
				return nestedUnansweredQListRecursiveRes;
		}
		// If above condition not met and there is unanswered question in current level, return
		const skipNavListConditions = (!isSkipNavigation || (isSkipNavigation && !isChildQsCompleted) || (isSkipNavigation && editableListQ))
		if (unansweredQList.length && skipNavListConditions) 
			return unansweredQList[0];
		// If both above condition not met and nesting of listQs is present, 
		if (listQuestionList[0].questions.length)
			return getUnansweredQListResponse(listQuestionList[0].questions);
		// If all questions answered, ignore scroll
		return null;
    };
    const unansweredQList = getUnansweredQListResponse((listQ.original_questions || listQ.questions), listQ.child_questions_completed_flag);
    if (unansweredQList) return unansweredQList;
  	}

	// 3. check for requiredQs with required validation or if listQs are displayed
	if (requiredQs.length) {
		if (unansweredQs.length && unansweredQs[0].display_type === 'list') {
			if (unansweredQs[0].child_questions_completed_flag) return unansweredQs[1];
			return unansweredQs[0];
		}
		return unansweredQs[0];
	}
	return unansweredQs[0];
}

export const updateAnswersArray = (
	payload = {
		sectionIndex: 0,
		response: '',
		questionId: '',
		hasEdit: false,
		hasMask: false,
		questionResponse: {},
		preventReflexiveCall: false,
		isError: false,
		validate: false,
		ignoreChildQuestionsOn: false,
		propQuestion: null,
		listParentId: '',
		listIndex: null,
		defaultCsrConfig: null,
		triggerValidation: null,
		transactionLog: [],
	},
) => {
	return (dispatch, getState) => {
		if (
			payload.response !== null ||
			payload.response !== undefined ||
			payload.isError
		) {
			const currentState = getState();
			const {
				breadcrumbs,
				activeSubsectionId,
				currentPageSeqNumber,
				eSign,
				role,
			} = currentState.questions;
			const currentBreadcrumbId = get(
				breadcrumbs,
				'currentBreadcrumb.id',
				'',
			);
			const {
				sectionIndex,
				response,
				questionId,
				preventReflexiveCall,
				validate,
				ignoreChildQuestionsOn,
				propQuestion,
				listParentId,
				listIndex,
				defaultCsrConfig,
				hasEdit,
				triggerValidation,
			} = payload;

			const currentSubSection = currentState.questions.sections[activeSubsectionId][sectionIndex];
			const { questionList } = currentSubSection;

			if (hasEdit) {
				const responseId = get(response, 'id');
				dispatch({
					type: UPDATE_LAST_FOCUSED_ELEMENT_ID,
					payload: {
						id: responseId || questionId,
					},
				});
			}

			if (questionId.indexOf('_$ureify_') > -1) {
				dispatch({
					type: UPDATE_QUESTION_ANSWER,
					currentBreadcrumbId,
					currentPageSeqNumber,
					payload: {
						...payload,
						questionId,
						propQuestion,
						eSign,
					},
					activeSubsectionId,
				});
				if (!preventReflexiveCall && propQuestion.child_questions) {
					dispatch(submitSectionQuestion(sectionIndex, {
						isReflexive: true,
						sectionIndex,
						preventReflexiveCall: true,
					}))	
				}
				return;
			}

			let question;
			let questionsArray = questionList
			if (listParentId && Number.isInteger(listIndex) && listIndex >= 0) {
				const listParentQ = questionList.filter(q => q.question_id === listParentId)[0];
				const listQ = listParentQ.questions[listIndex];
				// flatten questions in case any question in the list might have nested child questions
				const flattenedListQ = [];
				HELPER.flattenQuestions(listQ, flattenedListQ);
				[question] = flattenedListQ.filter(
					(q) => q.question_id === questionId,
				);
				questionsArray = flattenedListQ;
			} else if (listParentId) {
				const listParentQ = questionList.filter(q => q.question_id === listParentId)[0];
				const listQ = listParentQ.original_questions ? listParentQ.original_questions : listParentQ.questions;
				const flattenedListQ = [];
				HELPER.flattenQuestions(listQ, flattenedListQ);
				[question] = flattenedListQ.filter(
					(q) => q.question_id === questionId,
				);
				questionsArray = flattenedListQ;
			} else {
				[question] = questionList.filter(
					(q) => q.question_id === questionId,
				);
			}
			
			const parentId = get(question, 'properties.parent_group_id', '');
			const parentQuestion = questionList.filter(
				(q) => q.question_id === parentId,
			)[0];
			
			let isDataSelector = false;
			const currentQuestionSet = get(parentQuestion, 'questions', []);
            if(currentQuestionSet.length > 0) {
			   isDataSelector = currentQuestionSet[0].display_type === 'data_selector';
			}

			let reflexCall = false;
			if (!preventReflexiveCall && question && (question.child_questions || question.reflexive_question_flag)) {
				reflexCall = shouldMakeReflexiveCall(
					questionsArray,
					question.child_questions_on,
					questionId,
					response,
					ignoreChildQuestionsOn,
					question.reflexive_question_flag,
				);
			}
			// csr screens
			const { questionList: questionsList, answerList } = getCompiledQuestionsAnswersErrors(currentState);		
			const ctaBtnQuestionId = get(question, 'properties.cta_button.value');
			const csrGroup = get(question, 'properties.group.value');
			const isReadOnly = get(question, 'is_readonly', false);
			const csrConfigKey = csrGroup === 'finalQuote' ? 'finalQuoteCsrConfig' : 'csrConfig';
			let revisedCsrConfig = {};

			if (role === 'csr') {
				if (defaultCsrConfig) {
					revisedCsrConfig = defaultCsrConfig;
				} else if (ctaBtnQuestionId) {
					const { answersArray, errorsArray } = currentSubSection;
					const totalAnswerList = HELPER.addOrReplace(
						answersArray,
						{
							question_id: questionId,
							answer: response,
							immediateParent: propQuestion ? propQuestion.immediateParent : '',
							parentId: propQuestion ? propQuestion.parentId : '',
							parentListQuestionId: get(propQuestion, 'properties.parent_list_id', ''),
							listParentId,
							listIndex,
						},
						'answer',
					);

					const ctabBtnQuestion = questionsList.filter(q => q.question_id === ctaBtnQuestionId && activeSubsectionId === q.sectionId )[0];
					const requiredCsrSchema = get(ctabBtnQuestion, 'properties.required_questions_list', [])
					const csrKey = get(ctabBtnQuestion, 'properties.csr.value');

					if (ctabBtnQuestion && csrKey && requiredCsrSchema && requiredCsrSchema.length) {
						const requiredQuestionObjs = questionsList.filter(q => requiredCsrSchema.includes(q.question_id))
						const requiredQuestionList = requiredQuestionObjs.filter(q => get(q, 'validations.required.value', false) && !q.is_hidden)
						const actualRequiredAnswers = totalAnswerList.filter(ans => requiredQuestionList.find(q => q.question_id === ans.question_id));

						const errorList = HELPER.addOrReplace(
							errorsArray,
							{
								question_id: questionId,
								isError: payload.isError,
							},
							'isError',
						).filter((errorElement) => errorElement && errorElement.isError);

						// check if all required answers are valid with no errors
						const isValid = !errorList.some(err => requiredCsrSchema.includes(err.question_id)) && actualRequiredAnswers.every(ans => ans.answer || ans.answer === 0);

						if (requiredCsrSchema.includes(questionId) && csrKey) {
							const csrSection = get(question, 'properties.section.value')
							if (csrKey === 'rateClass') {
								revisedCsrConfig = {
									rateClass: {
										enable: isValid,
										show: false,
									},
									quoteResults: {
										enable: false,
										show: false,
									},
									paymentOptions: {
										enable: false,
										show: false,
									},
								}
							} else if (csrKey === 'quoteResults' && csrSection === 'rateClass') {
								let quoteResultsQs = questionsList.filter(q => get(q, 'properties.section.value', '') === 'quoteResults' && get(q, 'properties.group.value') !== 'finalQuote');
								if (csrGroup === 'finalQuote') {
									quoteResultsQs = questionsList.filter(q => get(q, 'properties.section.value', '') === 'quoteResults' && get(q, 'properties.group.value') === 'finalQuote');
								}
								// eslint-disable-next-line no-empty
								if (quoteResultsQs.every(q => q.is_hidden)) {
								} else {
									revisedCsrConfig = {
										quoteResults: {
											enable: isValid,
											show: false,
										},
									}
									if (hasEdit) {
										revisedCsrConfig.paymentOptions = {
											enable: false,
											show: false,
										};
									}
								}
							} else if (csrKey === 'paymentOptions' && csrSection === 'quoteResults') {
								revisedCsrConfig = {
									paymentOptions: {
										enable: isReadOnly ? false : isValid,
										show: !!isReadOnly,
									},
								}
							}
						}
					}
				}
			}

			// preseve csrConfig for reflexive call
			let reflexiveCsrConfig = null;
			const prevResp = get(answerList.find(ans => ans.questionId === questionId), 'answer');
			const currentResp = payload.response;
			// check if question response is changed
			if (prevResp !== currentResp && role === 'csr' && ctaBtnQuestionId) {
				reflexiveCsrConfig = {
					ctaBtnId: ctaBtnQuestionId,
					enabled: true,
				}
			}
			// preserve answer in session
			const preserveFlag = get(question, 'properties.preserve.value');
			if (preserveFlag) {
				const preservedAnswers = JSON.parse(window.sessionStorage.getItem('preservedAnswers')) || [];
				const existingIndex = preservedAnswers.findIndex(q => q.question_id);
				if (existingIndex >= 0) {
					preservedAnswers[existingIndex] = { question_id: questionId, answer: response }
				} else {
					preservedAnswers.push({ question_id: questionId, answer: response });
				}
				window.sessionStorage.setItem('preservedAnswers', JSON.stringify(preservedAnswers));
			}
			if(reflexCall || (question?.display_type === 'date' && question?.reflexive_question_flag)) {
				const { transactionLog } = payload; 
				const { meta } = currentState;
				const { os, userAgent, platform } = meta;
				const previousTransactionLog = transactionLog && transactionLog.length ? transactionLog : [];
				const timeStampData = {
					transactionLog: 
					[
						...previousTransactionLog,
						{
							time_start: getUTCTimeStamp(),
							time_end: getUTCTimeStamp(),
							os,
							browser: userAgent,
							platform,
						},
					],
					lastSubmittedAt: getUTCTimeStamp(),
				};
				dispatch({
					type: UPDATE_QUESTION_ANSWER_TIME_STAMP,
					payload : {
						questionId,
						timeStampData,
						activeSubsectionId,
						sectionIndex,
						meta,
					},
				})
			}
			dispatch({
				type: UPDATE_QUESTION_ANSWER,
				currentBreadcrumbId,
				currentPageSeqNumber,
				payload: {
					...payload,
					eSign,
					[csrConfigKey]: revisedCsrConfig,
					triggerValidation,
				},
				activeSubsectionId,
			});

			if(isDataSelector){
				dispatch({
					type: UPDATE_DATA_SELECTOR_OPTIONS,
					currentBreadcrumbId,
					currentPageSeqNumber,
					 payload: {
						 ...payload,
						 questionId,
						 parentQuestion,
					 },
					 activeSubsectionId,
				})
			}

			// check if reflexive answer
			if (!preventReflexiveCall) {
				if (reflexCall || (question?.display_type === 'date' && question?.reflexive_question_flag)) {
					dispatch(submitSectionQuestion(sectionIndex, {
						isReflexive: true,
						sectionIndex,
						preventReflexiveCall: true,
						...reflexiveCsrConfig && { csrConfig: reflexiveCsrConfig },
					}));
				}
			}
			if (validate && response) {
				dispatch(
					submitSectionQuestion(sectionIndex, {
						validate: true,
						sectionIndex,
						preventReflexiveCall: true,
					}),
				);
			}
		}
	};
};

export const updateQuestionAnswerTimeStamp = (questionId, timeStampData, sectionIndex) => {
	return (dispatch, getState) => {
		const currentState = getState();
			const {
				activeSubsectionId,
			} = currentState.questions;
		dispatch({
		type: UPDATE_QUESTION_ANSWER_TIME_STAMP,
			payload : {
				questionId,
				timeStampData,
				activeSubsectionId,
				sectionIndex,
			},
		})
	}
}

export const makeQuestionsCall = () => {
	return (dispatch, getState) => {
		const currentState = getState();
		const { uid } = currentState;
		const {
			breadcrumbs,
			currentPageSeqNumber,
			breadcrumbIndex,
		} = currentState.questions;
		const { breadcrumbList } = breadcrumbs;
		const currentBreadcrumbIndex = breadcrumbIndex - 1;
		if (
			currentBreadcrumbIndex > -1 &&
			breadcrumbList[currentBreadcrumbIndex].clickable
		) {
		 const currentBreadcrumb = breadcrumbList[currentBreadcrumbIndex];
		 const params = {
			uid,
			breadcrumb_id: currentBreadcrumb.breadcrumb_id,
			breadcrumb_nav_flag: true,
			page_sequence_number: currentPageSeqNumber,
		};
		dispatch(setCurrentBreadcrumb(currentBreadcrumb.breadcrumb_id));
        dispatch(getNextQuestionSet('/questions', params));
		}
	};
};

export const getNextPage = (params) => {
	return (dispatch, getState) => {
		const { uid, questions } = getState();
		const { currentPageSeqNumber } = questions;

		const reqParams = {
			uid,
			page_sequence_number: currentPageSeqNumber,
			...params,
		}

		dispatch(getNextQuestionSet('/questions', reqParams, {
			...reqParams,
		}));
	}
}

export const goToPreviousBreadCrumb =(sectionIndex)=>{
    return (dispatch) => {
	  /* #Commented Code for testing purpose
      const currentState = getState()
      const { currentPageSeqNumber } = currentState.questions;
      const { uid } = currentState;
      const reqParams = {
        uid,
        page_sequence_number:currentPageSeqNumber,
        prev_page_flag: true,
      }
	  */
	 
	// Calling submitSectionQuestion with backLink flag
	dispatch(submitSectionQuestion(sectionIndex, {
		sectionIndex,
		isBackLink: true,
	}));
	/* #Commented Code for testing purpose
      dispatch(getNextQuestionSet('/questions', reqParams, {
        ...reqParams,
        sectionIndex,
      }, reqParams));
    */
    }
  }