/* eslint-disable no-console */
import queryString from 'query-string';
import {
  getNextQuestionSet,
  getMockQuestionSet,
  setDestinationURL,
  triggerAuthAction,
  triggerOIDCgetApiAction,
  triggerOIDCpostApiAction,
} from './redux/actions';
import { SET_LOGIN_CONFIG } from './redux/types';
import { getUid, getSessionId, getParams, getCode } from './util';
import LocalStorageServices from './util/localStorageServices';
import router from './util/router';
import { mockAuthQuestions, mockUnauthorizedResponse, mockNotFoundResponse, mockInactiveSessionResponse } from './mockResponse';
import SessionStorageServices from './util/sessionStorageServices';
import { PROD, UAT, UAT2 } from './constants';

export default async function initApp(store, config) {
  const { getState, dispatch } = store;
  const { uid } = getState();
  const urlUid = getUid(window.location.search);
  const sessionId = getSessionId(window.location.search);
  const event = getParams('event');
  const envelopId = getParams('envelop_id');
  const documentType = getParams('document_type');
  const identity = SessionStorageServices.getIdentity(getParams('uid'));

  /**
   * @description Checks if login is enabled as per carrier's config.
   */
  let loginRedirection = false;
  const { features } = config;
  const { login, OIDCAuth } = features;

  /**
   * @description Sets login related configs in the reducer.
   */
  if (login) {
    dispatch({
      type: SET_LOGIN_CONFIG,
      payload: login,
    });
  }

  const accessToken = LocalStorageServices.getAccessToken();
  if (login.enable) {
    if (!accessToken) loginRedirection = true;
  }

  /**
   * @description Stores the URL requested by the user.
   */
  const { pathname, search } = window.location;
  const URL = pathname.concat(search);

  // Skip /questions API call if route contains document_type as url param
  if (documentType) {
    return;
  }

  const isPlanBasedRoute = process.env.REACT_APP_IS_PLAN_BASED_ROUTE === 'true';
  const lifePlan = process.env.REACT_APP_LIFE_PLAN;

  console.log('Initialize Routing logs', process.env.REACT_APP_IS_PLAN_BASED_ROUTE, isPlanBasedRoute, process.env.REACT_APP_LIFE_PLAN, lifePlan);

  // If identity is `invalid` redirect the user to access denied or not found page.
	if (identity === 'invalid' || pathname === '/inactivesession' || (isPlanBasedRoute && pathname === `/${lifePlan}/inactivesession`)) {
		dispatch(getMockQuestionSet(mockInactiveSessionResponse));
		return;
	}

  /**
   * @description Redirects the user to loginPage if loginRedirection is true,
   * else gets the access_tokens by making the /auth API call (carrier specific).
   */
  if (loginRedirection) {
    dispatch(setDestinationURL(URL));
    dispatch(getMockQuestionSet(mockAuthQuestions));
    return;
  }

  console.log('Is Auth API Enabled: ', process.env.REACT_APP_ENABLE_AUTH_API);
  if (process.env.REACT_APP_ENABLE_AUTH_API === 'TRUE' && !accessToken) {
    console.log('Begin Auth call', new Date().getTime());
    const hasAuthToken = await dispatch(triggerAuthAction());
    if (!hasAuthToken) {
      dispatch(getMockQuestionSet(mockNotFoundResponse));
    }
    console.log('End Auth call', new Date().getTime());
  }

  if (sessionId) {
    console.log('Begin questions call', new Date().getTime());
    dispatch(
      getNextQuestionSet('/questions', {
        session_id: sessionId,
      }),
    );
    console.log('End questions call', new Date().getTime());
    return;
  }

  const { routes: carrierRoutes = [], roles: userRoles = [] } = config;

  /**
   * if URL does not contain URL, then it's a new application.
   * Change it to /questions connect with APIs
   */
  if (urlUid) {
    if (urlUid !== uid) {
      if (event && event.search('ttl_expired') < 0) {
        dispatch(
          getNextQuestionSet('/questions', {
            uid: urlUid,
            event,
            envelop_id: envelopId,
            next_page_flag: 1,
          }),
        );
      } else if (OIDCAuth && OIDCAuth.enable) {
        const queryParams = queryString.parse(search);
        if (queryParams && Object.keys(queryParams).includes('role') && (userRoles && userRoles.includes(queryParams.role.toUpperCase()))) {
          if (queryParams.role.toUpperCase() === userRoles[0] || queryParams.role.toUpperCase() === userRoles[1]) {
            const role = (queryParams && queryParams.role && queryParams.role.toLowerCase()) || '';
            const navigationUrlObj = {
              url: window.location.href.replace(`&role=${role}`,''),
              role,
            }
            window.sessionStorage.setItem('navigationUrlObj', JSON.stringify(navigationUrlObj));
            dispatch(triggerOIDCgetApiAction());
          } else if (queryParams.role.toUpperCase() === userRoles[2] || queryParams.role.toUpperCase() === userRoles[3]) {
            const revisedParams = {};
            if (queryParams && queryParams.role) {
              revisedParams.role = queryParams.role.toLowerCase();
            }
            const params = {
              uid: urlUid,
              ...revisedParams,
            };
            dispatch(getNextQuestionSet('/questions', { ...params }));
          } else {
            dispatch(getMockQuestionSet(mockUnauthorizedResponse));
          }
        } else {
          console.log('Begin questions call', new Date().getTime());
          let params = {
            uid: urlUid,
          };
          const navigationUrlObj = JSON.parse(window.sessionStorage.getItem('navigationUrlObj'));
          const role = navigationUrlObj && navigationUrlObj.role;
          if (role) {
            const revisedParams = {};
            revisedParams.role = role.toLowerCase();
            params = {
              uid: urlUid,
              ...revisedParams,
            };
          } 
          dispatch(getNextQuestionSet('/questions', { ...params }));
          console.log('End questions call', new Date().getTime());
        }
      } else {
        console.log('Begin questions call', new Date().getTime());
        const params = {
          uid: urlUid,
        };
        dispatch(getNextQuestionSet('/questions', { ...params }));
        console.log('End questions call', new Date().getTime());
      }
    }
  } else if (pathname === '/init' || (isPlanBasedRoute && pathname === `/${lifePlan}/init`) || pathname === '/' || (isPlanBasedRoute && pathname === `/${lifePlan}`) || pathname.includes('/login') || (isPlanBasedRoute && pathname.includes(`/${lifePlan}/login`))) {
    if (search.includes('code=') || search.includes('saml2')) {
      const code = getCode(window.location.search);
      dispatch(triggerOIDCpostApiAction(code));
    } else {
      console.log('Begin questions call', pathname, new Date().getTime());
      // check if query string contain 'utm' tags
      const utmParamsRegex = /&utm_[a-zA-Z]+=/;
      if (utmParamsRegex.test(search)) {
        window.sessionStorage.setItem('utm_query_string', search.substring(1));
      } else {
        window.sessionStorage.removeItem('utm_query_string');
      }
      dispatch(getNextQuestionSet('/questions', {}, {}));
      console.log('End questions call', pathname, new Date().getTime());
    }
  } else if (carrierRoutes.includes(pathname)) {
    const queryParams = queryString.parse(search);

    // Disable amica specific route for csr role
    const directUrlRegex = /^\/onlinequote\/?\?([\w\d_+%-=&]+)|(&role=csr)$/gi;
    const utmParamsRegex = /&utm_[a-zA-Z]+=/;
    if ([UAT, UAT2, PROD].includes(process.env.REACT_APP_NODE_ENV) && directUrlRegex.test(URL) && !utmParamsRegex.test(search)) {
      dispatch(getMockQuestionSet(mockNotFoundResponse));
      return;
    }

    const revisedParams = {};
    Object.keys(queryParams).forEach((param) => {
      if (param.toUpperCase() === 'ROLE') {
        if (queryParams[param].toUpperCase() === 'CSR') {
          revisedParams.agent_code = 'AG0001';
          revisedParams.role = 'csr';
        }
      } else {
        revisedParams[param] = queryParams[param];
      }
    });
    const employeeRoutes = ['/employee', '/employee/'];
    if(isPlanBasedRoute){
      employeeRoutes.push(`/${lifePlan}/employee`,`/${lifePlan}/employee/`);
    }
    const params = {
      isEmployee: employeeRoutes.includes(pathname),
      route: pathname,
      ...revisedParams,
    };

    // check if query string contain 'utm' tags
    if (utmParamsRegex.test(search)) {
      window.sessionStorage.setItem('utm_query_string', search.substring(1));
    } else {
      window.sessionStorage.removeItem('utm_query_string');
    }

    router(dispatch, null, false, null);
    dispatch(getNextQuestionSet('/questions', { ...params }, {}));
  } else {
    dispatch(getMockQuestionSet(mockNotFoundResponse));
  }
}
