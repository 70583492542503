/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable no-mixed-operators */
import React from 'react';
import get from 'lodash.get';
import queryString from 'query-string';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import momenttz from 'moment-timezone';
import qs from 'qs';
import { isObject } from '../utils';
// import GetElementFunc  from './GetElement';
import { axiosInstance } from './axios';
import LocalStorageServices from './localStorageServices';

const Cookies = require('js-cookie');

export const checkTokenExpiry = () => {
	const currentTimestamp = Math.floor(new Date().getTime() / 1000);
	const expiresOn = LocalStorageServices.getTokenExpirationTime();
	if (!expiresOn) return true;
	return currentTimestamp > expiresOn;
}

// eslint-disable-next-line no-unused-vars
const isGoogleTagEventFired = (eventName) => {
	// returning false here indicates that we want to maintain duplicate entries.
	// e.g. if user is going back to contact info page from review, we need to add
	// it to tracker even though contact info page was already visited by the user earlier.
	return false;
	// let eventFired = false;
	// window.initialTagManager = window.initialTagManager || [];
	// for(let i=0; i<window.initialTagManager.length; i++) {
	//   if (window.initialTagManager[i].page_id == eventName) {
	//     eventFired = true;
	//     break;
	//   }
	// }
	// return eventFired;
};

function handleGtmVirtalPageVisitsUtil(questionsWithAnswerObj) {
	const searchParams = new URLSearchParams(window.location.search);
	const transactionId = searchParams.get('transaction_id');
	let questionWithAnswerObj;

	for (let i = 0; i < questionsWithAnswerObj.length; i += 1) {
		questionWithAnswerObj = questionsWithAnswerObj[i];
		const virtualPageVisitsQuestionsList =
			window.virtualPageVisitsQuestionsList || [];
		for (
			let j = 0;
			j < virtualPageVisitsQuestionsList.length;
			j += 1
		) {
			if (
				virtualPageVisitsQuestionsList[j].question_id ===
				questionWithAnswerObj.question_id
			) {
				const pageTitle = virtualPageVisitsQuestionsList[j].gtm_title;
				const pageId = virtualPageVisitsQuestionsList[j].gtm_id;

				if (!isGoogleTagEventFired(pageTitle)) {
					window.initialTagManager.push({
						page_title: pageId,
						page_id: pageId,
					});

					window.dataLayer.push({
						event: 'VirtualPageView',
						pagePath: pageId,
						pageTitle,
						VirtualPageVisitTransactionId: parseInt(
							transactionId || window.uniqueTransactionId,
							10,
						),
					});
				}
			}
		}
		const virtualPageVisitsQuestionsWithAnsConsidered =
			window.virtualPageVisitsQuestionsWithAnsConsidered || [];

		for (
			let j = 0;
			j < virtualPageVisitsQuestionsWithAnsConsidered.length;
			j += 1
		) {
			if (
				virtualPageVisitsQuestionsWithAnsConsidered[j].question_id ===
					questionWithAnswerObj.question_id &&
				isEqual(
					virtualPageVisitsQuestionsWithAnsConsidered[j].answer,
					questionWithAnswerObj.answer,
				)
			) {
				const pageTitle =
					virtualPageVisitsQuestionsWithAnsConsidered[j].gtm_title;
				const pageId = virtualPageVisitsQuestionsList[j].gtm_id;

				window.initialTagManager.push({
					page_title: pageId,
					page_id: pageTitle,
				});
				window.dataLayer.push({
					event: 'VirtualPageView',
					pagePath: pageId,
					pageTitle,
					VirtualPageVisitTransactionId: parseInt(
						transactionId || window.uniqueTransactionId,
						10,
					),
				});
			}
		}
	}
}

function collectQuestionsList(questionsResponse, questionsArray) {
	let questionsObj = null;
	if (Array.isArray(questionsResponse)) {
		questionsObj = [...questionsResponse];
	} else if (isObject(questionsResponse)) {
		questionsObj = { ...questionsResponse };
	}
	if (questionsObj.questions !== undefined) {
		questionsObj = questionsObj.questions;
		if (!Array.isArray(questionsObj)) {
			return;
		}
	}
	questionsObj.forEach((question) => {
		questionsArray.push(question);
		const { questions } = question;
		if (questions && questions.length) {
			collectQuestionsList(questions, questionsArray);
		}
	});
}

export function handleGtmVirtalPageVisits(questionsResponse) {
	let questionsArray = [];
	if (
		questionsResponse &&
		questionsResponse.data &&
		questionsResponse.data.response &&
		questionsResponse.data.response &&
		questionsResponse.data.response.data &&
		questionsResponse.data.response.data.questionnarie &&
		questionsResponse.data.response.data.questionnarie.questions
	) {
		collectQuestionsList(
			questionsResponse.data.response.data.questionnarie.questions,
			questionsArray,
		);
	}
	if (Array.isArray(questionsResponse)) {
		questionsArray = questionsResponse;
	}
	handleGtmVirtalPageVisitsUtil(questionsArray);
}

export const formatAmount = (
	amount,
	maximumFractionDigits,
	currency,
) => {
	if (currency === 'years') {
		return `${parseFloat(amount).toLocaleString(undefined, {
			maximumFractionDigits,
			minimumFractionDigits: maximumFractionDigits,
		})} ${currency}`;
	}

	return (
		currency +
		parseFloat(amount).toLocaleString(undefined, {
			maximumFractionDigits,
			minimumFractionDigits: maximumFractionDigits,
		})
	);
};

export const ErrorTypes = {
	noError: 'NO_ERROR',
	required: 'REQUIRED',
	mismatch: 'MISMATCH',
	minLength: 'MIN_LENGTH',
	minValue: 'MIN_VALUE',
	maxValue: 'MAX_VALUE',
	allZeros: 'ALL_ZEROS',
	backendError: 'BACKEND_VALIDATION',
};

const POSITION = {
	PREFIX: 'prefix',
	POSTFIX: 'postfix',
};

export const formatMetricData = (res, metricObj) => {
	if (metricObj && metricObj.value) {
		if (metricObj.displayPosition) {
			if (metricObj.displayPosition === POSITION.POSTFIX) {
				if (/^[a-zA-Z]+$/.test(metricObj.value))
					return `${res} ${metricObj.value}`;
				return res + metricObj.value;
			}
			if (metricObj.displayPosition === POSITION.PREFIX) {
				return metricObj.value + res;
			}
			return `${res} ${metricObj.value}`;
		}
	}
	return res;
};

export const getURLSearchParams = (uid, search) => {
	const params = queryString.parse(search);
	if (params) {
		return `?${new URLSearchParams({ uid, ...params }).toString()}`;
	}
	return `?uid=${uid}`;
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getUid = (location) => {
	const values = queryString.parse(location);
	return values.uid;
};

export const getSessionId = (location) => {
	const values = queryString.parse(location);
	return values.session_id;
};
export const getQueryParamValue = (location, queryKey) => {
	const values = queryString.parse(location);
	return values[queryKey];
};

export const getSamlIdpTokenFromCookie = (cookieName) => {
    const cookie = Cookies.get(cookieName);
    Cookies.remove(cookieName);
    return cookie;
};

export const getCode = (location) => {
    const values = queryString.parse(location);
    if(values.code){
        return { code: values.code }
    }
    const saml2Cookie = `${values.saml2}_SAMLResponse`;
    return { SAMLResponse: getSamlIdpTokenFromCookie(saml2Cookie) }
};

export const scrollUP = (behavior = 'auto') => {
	if ('scrollBehavior' in document.documentElement.style) {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior,
		});
	} else {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 800);
	}
};

export const scrollToRef = (ref) => {
	window.scrollTo(0, ref.current.offsetTop - 20);
};

export const getPlanId = (tenure) => `YourLife-${tenure}`;

export const getDefaultPlanId = (id) => {
	return id.replace('YourLife-', '');
};

export const getDefaultPlan = (options) => {
	if (options.length > 0) {
		return options.filter((option) => option.default)[0];
	}
	return {};
};

export const reformatPlans = (options) => {
	return options.map((option) => {
		return {
			id: option.id,
			title: option.title,
			subtitle: option.premium.title,
			footer: option.premium.premium_frequency_label,
			amount: formatAmount(
				option.premium.premium,
				2,
				option.premium.currency_symbol,
			),
		};
	});
};

export const formatDate = (data) => {
	let value = data;
	value = value.replace(/\//gi, '');
	return `${data.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
		4,
		8,
	)}`;
};

export const forceScroll = () => {
	window.onbeforeunload = () => {
		scrollUP();
	};
};

export const saveException = (exceptionDetails) => {
	const { error } = new Error();
	// eslint-disable-next-line no-console
	console.error(error);
	const uid = new URLSearchParams(window.location.search).get('uid');
	const inputJSON = {
		uid,
		exceptionMessage: exceptionDetails.message.toString(),
		exceptionAPI: exceptionDetails.apiName,
		exceptionDatatime: new Date().toUTCString(),
	};
	const data = { 'data' : JSON.stringify(inputJSON) };
	// console.log(inputJSON);
	return axiosInstance.post('/logerror', data);
};

export const getUserAge = (date, today) => {
	if (date != null) {
		let years = today.getFullYear() - date.getFullYear();

		if (
			today.getMonth() < date.getMonth() ||
			(today.getMonth() === date.getMonth() &&
				today.getDate() < date.getDate())
		) {
			years -= 1;
		}
		if (years < -1) {
			return -1;
		}

		return years;
	}
	return false;
};

const getRequiredSchema = (questionsResponse, schema) => {
	let questionsObj = null;
	if (Array.isArray(questionsResponse)) {
		questionsObj = [...questionsResponse];
	} else if (isObject(questionsResponse)) {
		questionsObj = { ...questionsResponse };
	}
	if (questionsObj.questions !== undefined) {
		questionsObj = questionsObj.questions;
	}
	questionsObj.forEach(
		({ validations, question_id, question_type, questions }) => {
			if (
				question_type !== 'group' &&
				validations &&
				validations.required &&
				Boolean(validations.required.value) === true
			) {
				schema.push(question_id);
			}
			if (questions && questions.length) {
				getRequiredSchema(questions, schema);
			}
		},
	);
};

export const CreateRequiredSchema = (questionsResponse) => {
	const schema = [];
	getRequiredSchema(questionsResponse, schema);
	return schema;
};

export const Plans = {
	MONTHLY: { id: '1', label: 'MONTHLY', name: 'Monthly' },
	ANNUALLY: { id: '2', label: 'ANNUALLY', name: 'Annual' },
};

export const GlobalAPIHeaders = {
	Accept: 'application/json',
	'Cache-Control': 'no-cache, no-store, must-revalidate',
	Pragma: 'no-cache',
	Expires: '0',
};

export const LIST_ACTION = {
	ADD: 'Add',
	UPDATE: 'Update',
	CANCEL: 'Cancel',
	NEXT: 'next',
	DELETE: 'delete',
};

export const MessageType = {
	ERROR: 'Error',
	WARN: 'Warn',
	INFO: 'Info',
	SUCCESS: 'Success',
};

export const groupedQuestionType = {
	group: 'questions_group',
	list: 'list',
	assement_group: 'assessment-factor-group',
	assement_extend: 'question-group-extended',
};

const shouldContainNoError = (currentValue) =>
	currentValue === ErrorTypes.noError;

const validateQuestionsArr = (requiredElemsArr, response) => {
	/**
	 * Remember response is an array
	 * we have to find the property should exist as question_id
	 */
	if (requiredElemsArr && response) {
		let responseData = response;
		responseData = responseData.map((res) => res.question_id);
		return requiredElemsArr.every((element) => {
			return responseData.includes(element);
		});
	}
	return false;
};

export const ToggleContinueButton = (
	requiredSchemaTrue,
	questionsArr,
	errors,
) => {
	// console.log(questionsArr, requiredSchemaTrue,errors)
	const containsNoError = Object.entries(errors).every(([, value]) =>
		shouldContainNoError(value),
	);

	const validatedQuestionsArr = validateQuestionsArr(
		requiredSchemaTrue,
		questionsArr,
	);
	return containsNoError && validatedQuestionsArr;
};

export const QUESTION_TYPE = {
	SINGLE_SELECTION: 'singleselection',
	MULTI_SELECTION: 'multiselection',
};

export const formatAnswer = (questionObj) => {
	const response = questionObj.response
		? questionObj.response
		: questionObj.answer;
	let formatted_value = response ? response.toString() : '';
	switch (questionObj.question_type) {
		case QUESTION_TYPE.SINGLE_SELECTION:
			formatted_value =
				response && response.label ? response.label : formatted_value;
			break;
		case QUESTION_TYPE.MULTI_SELECTION:
			formatted_value =
				response && Array.isArray(response)
					? response.map((item) => item.label).join(', ')
					: formatted_value;
			break;
		default:
			break;
	}
	return formatted_value;
};

export const isMobile = () => {
	return (
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
		navigator.userAgent.match(/Opera Mini/i) ||
		navigator.userAgent.match(/IEMobile/i)
	);
};

export const isIPad=()=>{
	return navigator.userAgent.match(/iPad|iPod/i)
}

export const parseResponse = (res) => {
	const data = get(res, 'data.response.data', null);
	const questionnaire = get(
		res,
		'data.response.data.questionnarie',
		null,
	);
	const other_params = get(res, 'data.response.other_params', null);
	const page_desc = get(res, 'data.response.page_desc', null);
	const breadcrumbs = get(res, 'data.response.breadcrumbs', null);
	return {
		data,
		questionnaire,
		other_params,
		page_desc,
		breadcrumbs,
	};
};

/**
 * @description function compares the current location with the current status of
 * the API response and if needed, routes the application.
 * @param {Object} props to access route props.
 * @param {Object} response return by the API.
 */
export const redirectIfNeeded = (
	props,
	response,
	stateParams = {},
) => {
	const { location, history } = props;
	const { search, pathname: currentLocation } = location;
	const { other_params } = parseResponse(response);
	if (
		currentLocation
			.toLowerCase()
			.match(other_params.current_status.toLowerCase())
	) {
		return false;
	}
	history.push(
		`/${other_params.current_status.toLowerCase()}${search}`,
		{
			response: JSON.stringify(response),
			...stateParams,
		},
	);
	return true;
};

/**
 * @description function initiate /question api call
 * with next or previous flag.
 * @param {Object} formParams from the parent component.
 * @param {String} pageSequenceNumber indicates current page in questions flow.
 * @param {String} searchParam current search parameter in URL.
 */
export const callQuestionsAPI = async (
	formParams,
	pageSequenceNumber,
	searchParam,
) => {
	try {
		let commonGlobalParams = { uid: getUid(searchParam) };

		commonGlobalParams = {
			...commonGlobalParams,
			...formParams,
			'page_sequence_number': pageSequenceNumber,
		};

		return await axiosInstance.post('/questions', commonGlobalParams);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('error, ', err);
	}
	return false;
};

/**
 *
 * @param {String} searchParam current URL's search parameter.
 * @param {String} uriPath api end point
 * @param {String} questionId
 */
export const axiosCallHelper = async (
	props,
	uriPath,
	questionId,
	params,
) => {
	try {
		// const globalParams = getGlobalParams(props);
		const globalParams = { uid: getUid(props.location.search) };
		const dataForCompeltedQues = {
			...globalParams,
			...questionId,
			...params,
		};
		return await axiosInstance.post(
			uriPath,
			dataForCompeltedQues,
		);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error', error);
	}
	return false;
};

/**
 * @description extracts response from location state, and converts to
 * object if the string is stored.
 * If response is not present, makes a /questions api call.
 * @param {Object} props component props to access location properties
 */
export const getResponseFromLocation = async (props) => {
	let response = get(props, 'location.state.response', null);
	if (response) {
		response =
			typeof response === 'string' ? JSON.parse(response) : response;
		return response;
	}
	const res = await axiosCallHelper(props, '/questions');
	return res;
};

/**
 * @description if redirect url is present, load new page.
 * @param {Object} response from api call.
 */
export const checkLoginRedirection = (response) => {
	const url = get(response, 'data.response.loginRedirectUrl', '');
	if (url) {
		window.location.href = url;
	}
};

export const fireVirtualPageEventIfNecessary = (
	page,
	uid,
	transactionId,
) => {
	const pageTitle = page.page_title;
	const pageId = page.page_id;

	if (!isGoogleTagEventFired(pageId)) {
		window.initialTagManager = window.initialTagManager || [];
		window.initialTagManager.push({
			page_id: pageTitle,
			page_title: pageTitle,
		});
		window.dataLayer.push({
			event: 'VirtualPageView',
			pagePath: pageTitle,
			pageTitle: pageId,
			VirtualPageVisitTransactionId: parseInt(
				transactionId || window.uniqueTransactionId,
				10,
			),
		});
	}
};

export const handleGtmQuestionsSubmission = (questionsWithAnswer) => {
	if (questionsWithAnswer) {
		const questionsWithAnswerObj = JSON.parse(questionsWithAnswer);
		let questionWithAnswerObj;
		const gtmQuestionsList = window.gtmQuestionsList || [];

		for (let j = 0; j < gtmQuestionsList.length; j += 1) {
			if (
				gtmQuestionsList[j].type === 'value' &&
				gtmQuestionsList[j].value
			) {
				window.dataLayer.push({
					[gtmQuestionsList[j].gtm_id]: gtmQuestionsList[j].value,
				});
			}
		}

		for (let i = 0; i < questionsWithAnswerObj.length; i += 1) {
			questionWithAnswerObj = questionsWithAnswerObj[i];

			if (questionWithAnswerObj.question_id) {
				for (let j = 0; j < gtmQuestionsList.length; j += 1) {
					// eslint-disable-next-line no-empty
					if (
						gtmQuestionsList[j].type === 'value' &&
						gtmQuestionsList[j].value
					// eslint-disable-next-line no-empty
					) {
					} else if (
						gtmQuestionsList[j].question_id ===
						questionWithAnswerObj.question_id
					) {
						if (gtmQuestionsList[j].type) {
							if (
								gtmQuestionsList[j].type.toLowerCase() === 'boolean'
							) {
								window.dataLayer.push({
									[gtmQuestionsList[j].gtm_id]: true,
								});
							} else {
								window.dataLayer.push({
									[gtmQuestionsList[j].gtm_id]:
										questionWithAnswerObj.answer,
								});
							}
						} else {
							window.dataLayer.push({
								[gtmQuestionsList[j].gtm_id]:
									questionWithAnswerObj.answer,
							});
						}
					}
				}
			}
		}
	}
};

export const handleGtmPrefilledDataBeforeQuestions = (obj) => {
	if (
		obj &&
		obj.data &&
		obj.data.tag_manager_fields &&
		obj.data.tag_manager_fields.gtmQuestionsList
	) {
		const { gtmQuestionsList } = obj.data.tag_manager_fields;
		for (let j = 0; j < gtmQuestionsList.length; j += 1) {
			if (
				gtmQuestionsList[j].type === 'value' &&
				gtmQuestionsList[j].value
			) {
				window.dataLayer.push({
					[gtmQuestionsList[j].gtm_id]: gtmQuestionsList[j].value,
				});
			}
		}
	}
};

export const getParams = (searchString) => {
	const url = new URL(window.location.href);
	return url.searchParams.get(searchString);
};

export const converToArrayIfRequired = (obj) => {
	if (isObject(obj)) {
		return Object.keys(obj).reduce((acc, key) => {
			acc.push(obj[key]);
			return acc;
		}, []);
	}
	return obj;
};

export const convertQueryStringToObject = (query) => qs.parse(query);

/**
 * @description checks for the given value, if it's a string, remove trailing spaces.
 * Updating regular expression for the questions is going to be risky, and we would have
 * to update regex for all the questions.
 * @param {Any} text
 */
export const trimSpaces = (text) => {
	if (typeof text === 'string') {
		return text.trim();
	}
	return text;
};

export const getGlobalParams = (props) => ({
	user_ip_address: props.main.ipAddress,
	current_date_time: moment(),
	user_time_zone: momenttz.tz.guess(),
	browser: navigator.userAgent,
	sourcePage: props.location.pathname,
	sourceURL: props.location.search,
	...getParams(props.location.search),
	// ...props.main.queryParams
});

export const scrollViewToTop = () => {
	const options = {
		top: 0,
		left: 0,
	};
	window.scrollTo(options);
};

/**
 * Searches for key in js object (for case-insensitive key searches)
 */
const findKey = (object, key) =>
	Object.keys(object).find(
		(k) => k.toLowerCase() === key.toLowerCase(),
	);

/**
 * Parses string into js object
 */
const parseString = (searchString) =>
	qs.parse(searchString, { ignoreQueryPrefix: true });

export const savePathname = () => {
	const _uid = findKey(
		parseString(this.props.location.search),
		'uid',
	);
	const key = `${this.props.location.search.replace(
		`?${_uid}=`,
		'',
	)}-pathname`;
	window.sessionStorage.setItem(key, this.props.location.pathname);
};

export const preventUserActionIfOffline = () => {
	if (!navigator.onLine) {
		// eslint-disable-next-line no-alert
		window.alert('Please check your internet connectivity.');
		return true;
	}
	return false;
};

export const checkErrorPageRouting = (error, push, search) => {
	if (error.toString().match(/network error/i)) {
		push(`/network-error${search}`);
	} else {
		push(`/error${search}`);
	}
};

export const getBreadcrumbStatus = (breadcrumbs) => {
	const active = breadcrumbs.filter(
		({ state }) => state === 'active',
	);
	const incomplete = breadcrumbs.filter(
		({ state }) => state === 'incomplete',
	);
	const completed = breadcrumbs.filter(
		({ state }) => state === 'completed',
	);

	return [active, incomplete, completed];
};

// polyfilled smooth scrolling for IE, Edge & Safari
export const smoothScrollTo = (to, duration) => {
	const element =
		document.scrollingElement || document.documentElement;
	const start = element.scrollTop;
	const change = to - start;
	const startDate = +new Date();

	// t = current time
	// b = start value
	// c = change in value
	// d = duration
	const easeInOutQuad = (t, b, c, d) => {
		t /= d / 2;
		if (t < 1) return (c / 2) * t * t + b;
		t -= 1;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	};

	const animateScroll = (_) => {
		const currentDate = +new Date();
		const currentTime = currentDate - startDate;
		// eslint-disable-next-line radix
		element.scrollTop = parseInt(
			easeInOutQuad(currentTime, start, change, duration),
		);
		if (currentTime < duration) {
			requestAnimationFrame(animateScroll);
		} else {
			element.scrollTop = to;
		}
	};
	animateScroll();
};

export const formatAddress = (obj, sendIndividualQuestions) => {
	const { address, city, state, zip } = obj;
	return sendIndividualQuestions ? `${address.value}, ${city.value}, ${state.value}, ${zip.value ? zip.value : ''}` : `${address}, ${city}, ${state}, ${zip}`;
};

export const getRole = () => {
	let role = getParams('role') ;
	if(!role){
		const navigationUrlObj = JSON.parse(window.sessionStorage.getItem('navigationUrlObj'));
		role = navigationUrlObj && navigationUrlObj.role;
	}
	console.log('role is', role)
	return role || '';
}

export const getTabId = () => {
	let tabId = sessionStorage.getItem('tabId');
	if(tabId) {
		return tabId;
	}
	tabId = crypto.randomUUID();
	sessionStorage.setItem('tabId', tabId);
	return tabId;
}